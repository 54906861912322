<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Productivity Hacks: <span class="text_primary d-inline-block">Structure Your Day</span></h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item">
                              <router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
                                 Home
                              </router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'work_better' }">Work Better </router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Structure Your Day</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                    <a class="d-none page-link border-0 text_black"  aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </a>
                                 </li>
                                 <li class="page-item">
                                    <router-link :to=" { name: 'productivity_multitasking' }"
                                       class="page-link border-0 text_black" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Multitasking Myth</span>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- image text start -->
      <section class="image_text_sec text_left_right_image pt_50 pb_50 beauty_sleep">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="image_text_right_side">
                           <h2 class="text_black mb_22">Want to be more productive?</h2>
                           <p class="text_black mb_30">
                              The quest to get more done is something that most of us are after.  But you may be surprised to know that the path to greater productivity isn’t always about doing more.   But rather, it’s about adopting strategies to be more effective, efficient and focused.  That is the key to making the most of your time. 
                           </p>
                           <!-- <div>
                              <a href="#" class="btn btn_outline">
                                 <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                    viewBox="0 0 31 31">
                                    <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                                    <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                       stroke-linejoin="round" stroke-miterlimit="10">
                                       <path
                                          d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                          stroke="none"></path>
                                       <path
                                          d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                          stroke="none" fill="#fff"></path>
                                    </g>
                                 </svg>
                                 Watch Video Now
                              </a>
                           </div> -->
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="image_left_side">
                           <img src="../assets/images/work_better/pr_s.png" alt="img">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#card_sec',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                           <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
         </div>
      </section>
      <!-- image text end -->

      <!-- card start -->

      <section id="card_sec" class="card_sec my_100">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-10">
                  <div class="row">
                     <div class="col-lg-6 col-xl-4">
                        <div class="icon_border_card custome_border border_black">
                           <div class="mb_24"><img src="../assets/images/work_better/pro1.svg" alt="img"
                                 class="box_icon">
                           </div>
                           <p class="font_size_22  mb_14 border_heading"> Know Your Rhythms </p>
                           <p class="font_size_22 text_balticsea mb_14"> If you want to maximize your productivity, learn when you do what’s best. 
                           </p><p class="font_size_22 text_balticsea mb_0">
Are you better at detailed work in the morning?  Creative stuff in the afternoon?  Schedule tasks according to your natural rhythms. 
                           </p>
                        </div>
                     </div>

                     <div class="col-lg-6 col-xl-4">
                        <div class="icon_border_card custome_border border_black">
                           <div class="mb_24"><img src="../assets/images/work_better/pro2.svg" alt="img"
                                 class="box_icon">
                           </div>
                           <p class="font_size_22  mb_14 border_heading">Deep Dive </p>
                           <p class="font_size_22 text_balticsea mb_14">Schedule in uninterrupted time on your calendar for deep dive, heads-down work.  



                           </p>
                           <p class="font_size_22 text_balticsea mb_0">This means no meetings, emails, calls, texts, Slack messages or anything else that will take you off of your game. </p>
                        </div>
                     </div>

                     <div class="col-lg-6 col-xl-4">
                        <div class="icon_border_card custome_border border_black">
                           <div class="mb_24"><img src="../assets/images/work_better/pro3.svg" alt="img"
                                 class="box_icon">
                           </div>
                           <p class="font_size_22  mb_14 border_heading"> Embrace Routines</p>
                           <p class="font_size_22 text_balticsea mb_14">
                              One of the best ways to stay productive is to create a routine for yourself.  That may include quiet morning time to plan your day, or an end of the day routine to plan your next day. 



                           </p>
                           <p class="font_size_22 text_balticsea mb_0">Planning apps may help. 
                           </p>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </section>

      <!-- card end -->

      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">Discover more productivity tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'beauty_sleep' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img src="../assets/images/beauty-sleep/d1.png"
                                    alt="img" class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Increase your energy</h6>
                                 <p>How to manage your energy vs. your time. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'how_it_works' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <div class="flex-shrink-0 media-left">
                                    <img src="../assets/images/beauty-sleep/d2.png" alt="img" class="img-fluid">
                                 </div>

                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Pomodoro technique </h6>
                                 <p>Manage your time with the Pomodoro Technique.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sensory_overload_spot' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/beauty-sleep/d3.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sensory overload</h6>
                                 <p>Check out these hacks to avoid headaches, eye and ear fatigue and more..</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'productivity_structure' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/beauty-sleep/d4.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Productivity hacks</h6>
                                 <p>Productivity hacks to help you crush your work day. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'time_management_energy' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/beauty-sleep/d5.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Time management</h6>
                                 <p>Manage your time between work & downtime. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'enhanced_focus_concentration' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/beauty-sleep/d6.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Enhanced focus</h6>
                                 <p>Get focused & get productive with these focus enhancing tips. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>
<script>
   export default {
      name: "productivity_structure",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      },
   }
</script>